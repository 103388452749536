import {Component, inject} from '@angular/core'
import version from '../assets/package.json'
import {ConfigService} from './services/config.service'
import {AsyncPipe} from '@angular/common'
import {RouterOutlet} from '@angular/router'
import {HeaderComponent} from './common/header/header.component'
import {FooterComponent} from '@sparbanken-syd/sparbanken-syd-theme'

@Component({
  selector: 'spb-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [
    HeaderComponent,
    RouterOutlet,
    AsyncPipe,
    FooterComponent
  ]
})
export class AppComponent {
  public version = version.version
  public configService = inject(ConfigService)
}
