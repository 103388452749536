import {
  ApplicationConfig,
  importProvidersFrom,
  inject,
  LOCALE_ID,
  provideAppInitializer,
  provideExperimentalZonelessChangeDetection
} from '@angular/core'
import {provideRouter, withHashLocation} from '@angular/router'

import routes from './app-routes'
import {BrowserModule} from '@angular/platform-browser'
import {MatMenuModule} from '@angular/material/menu'
import {MatIconModule} from '@angular/material/icon'
import {MatButtonModule} from '@angular/material/button'
import {MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle} from '@angular/material/dialog'
import {
  MAT_LUXON_DATE_ADAPTER_OPTIONS,
  MAT_LUXON_DATE_FORMATS,
  MatLuxonDateModule
} from '@angular/material-luxon-adapter'
import {LOCAL_STORAGE_PROVIDERS} from './application/localstorage.provider'
import {MAT_DATE_FORMATS} from '@angular/material/core'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'
import {ConfigService} from './services/config.service'
import {provideHttpClient, withInterceptors} from '@angular/common/http'
import {authInterceptor} from './application/auth.interceptor'
import {responseInterceptor} from './application/response.interceptor'
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async'

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      BrowserModule,
      // Theme for header and footer etc.
      // Tons of Material needed for header
      MatMenuModule,
      MatIconModule,
      MatButtonModule,
      MatDialogContent,
      MatDialogTitle,
      MatDialogActions,
      MatDialogClose,
      MatLuxonDateModule),
    provideExperimentalZonelessChangeDetection(),
    provideAnimationsAsync(

    ),
    provideRouter(routes,
      withHashLocation()),
    provideHttpClient(withInterceptors([authInterceptor, responseInterceptor])),
    {provide: LOCALE_ID, useValue: 'sv-SE'},
    LOCAL_STORAGE_PROVIDERS,
    {provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true, firstDayOfWeek: 1}},
    {provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS},
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {
        subscriptSizing: 'dynamic',
        hideRequiredMarker: true,
        appearance: 'outline',
        floatLabel: 'always'
      }
    },
    provideAppInitializer(() => {
      const s = inject(ConfigService)
      return s.bootstrap()
    })
  ]
}
